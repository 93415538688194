import validate from "/codebuild/output/src641291346/src/gx-booking/node_modules/.pnpm/nuxt@3.6.5_@types+node@20.4.5_eslint@8.46.0_sass@1.64.2_typescript@5.1.6_vue-tsc@1.8.8/node_modules/nuxt/dist/pages/runtime/validate.js";
import account_45context_45slug_45global from "/codebuild/output/src641291346/src/gx-booking/middleware/account-context-slug.global.ts";
import auth_45check_45global from "/codebuild/output/src641291346/src/gx-booking/middleware/auth-check.global.ts";
import layout_45switcher_45global from "/codebuild/output/src641291346/src/gx-booking/middleware/layout-switcher.global.ts";
import trailing_45slash_45global from "/codebuild/output/src641291346/src/gx-booking/middleware/trailing-slash.global.ts";
export const globalMiddleware = [
  validate,
  account_45context_45slug_45global,
  auth_45check_45global,
  layout_45switcher_45global,
  trailing_45slash_45global
]
export const namedMiddleware = {}