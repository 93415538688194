import { default as _404RPqIbvBhxRMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/404.vue?macro=true";
import { default as _91_46_46_46slug_93LvvROquL35Meta } from "/codebuild/output/src641291346/src/gx-booking/pages/[...slug].vue?macro=true";
import { default as _91_91filter_93_931kCrbmgoNCMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/account/bookings/[[filter]].vue?macro=true";
import { default as indexbTqwAcFnxyMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/account/index.vue?macro=true";
import { default as notificationslvW6FriJ3IMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/account/notifications.vue?macro=true";
import { default as payments5i3mOWnk9zMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/account/payments.vue?macro=true";
import { default as personal_45infoUMPJGnMz4vMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/account/personal-info.vue?macro=true";
import { default as securityWYvrta6DY3Meta } from "/codebuild/output/src641291346/src/gx-booking/pages/account/security.vue?macro=true";
import { default as confirmF5YlDVypBnMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue?macro=true";
import { default as indexa3RE5lnJ2XMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue?macro=true";
import { default as _91bookingId_93UDNW236VSCMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue?macro=true";
import { default as _91accessKey_93QOrOf6EIs2Meta } from "/codebuild/output/src641291346/src/gx-booking/pages/gratuity/[accessKey].vue?macro=true";
import { default as loginPPEL7a4GtYMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/login.vue?macro=true";
import { default as reset_45password0OJAAz7DRWMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/reset-password.vue?macro=true";
import { default as set_45passwordIKgtAPxc4jMeta } from "/codebuild/output/src641291346/src/gx-booking/pages/set-password.vue?macro=true";
export default [
  {
    name: _404RPqIbvBhxRMeta?.name ?? "404",
    path: _404RPqIbvBhxRMeta?.path ?? "/404",
    meta: _404RPqIbvBhxRMeta || {},
    alias: _404RPqIbvBhxRMeta?.alias || [],
    redirect: _404RPqIbvBhxRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93LvvROquL35Meta?.name ?? "slug",
    path: _91_46_46_46slug_93LvvROquL35Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93LvvROquL35Meta || {},
    alias: _91_46_46_46slug_93LvvROquL35Meta?.alias || [],
    redirect: _91_46_46_46slug_93LvvROquL35Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_91filter_93_931kCrbmgoNCMeta?.name ?? "account-bookings-filter",
    path: _91_91filter_93_931kCrbmgoNCMeta?.path ?? "/account/bookings/:filter?",
    meta: _91_91filter_93_931kCrbmgoNCMeta || {},
    alias: _91_91filter_93_931kCrbmgoNCMeta?.alias || [],
    redirect: _91_91filter_93_931kCrbmgoNCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/account/bookings/[[filter]].vue").then(m => m.default || m)
  },
  {
    name: indexbTqwAcFnxyMeta?.name ?? "account",
    path: indexbTqwAcFnxyMeta?.path ?? "/account",
    meta: indexbTqwAcFnxyMeta || {},
    alias: indexbTqwAcFnxyMeta?.alias || [],
    redirect: indexbTqwAcFnxyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: notificationslvW6FriJ3IMeta?.name ?? "account-notifications",
    path: notificationslvW6FriJ3IMeta?.path ?? "/account/notifications",
    meta: notificationslvW6FriJ3IMeta || {},
    alias: notificationslvW6FriJ3IMeta?.alias || [],
    redirect: notificationslvW6FriJ3IMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/account/notifications.vue").then(m => m.default || m)
  },
  {
    name: payments5i3mOWnk9zMeta?.name ?? "account-payments",
    path: payments5i3mOWnk9zMeta?.path ?? "/account/payments",
    meta: payments5i3mOWnk9zMeta || {},
    alias: payments5i3mOWnk9zMeta?.alias || [],
    redirect: payments5i3mOWnk9zMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/account/payments.vue").then(m => m.default || m)
  },
  {
    name: personal_45infoUMPJGnMz4vMeta?.name ?? "account-personal-info",
    path: personal_45infoUMPJGnMz4vMeta?.path ?? "/account/personal-info",
    meta: personal_45infoUMPJGnMz4vMeta || {},
    alias: personal_45infoUMPJGnMz4vMeta?.alias || [],
    redirect: personal_45infoUMPJGnMz4vMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/account/personal-info.vue").then(m => m.default || m)
  },
  {
    name: securityWYvrta6DY3Meta?.name ?? "account-security",
    path: securityWYvrta6DY3Meta?.path ?? "/account/security",
    meta: securityWYvrta6DY3Meta || {},
    alias: securityWYvrta6DY3Meta?.alias || [],
    redirect: securityWYvrta6DY3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/account/security.vue").then(m => m.default || m)
  },
  {
    name: confirmF5YlDVypBnMeta?.name ?? "experiences-parentId-serviceId-confirm",
    path: confirmF5YlDVypBnMeta?.path ?? "/experiences/:parentId()/:serviceId()/confirm",
    meta: confirmF5YlDVypBnMeta || {},
    alias: confirmF5YlDVypBnMeta?.alias || [],
    redirect: confirmF5YlDVypBnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/experiences/[parentId]/[serviceId]/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexa3RE5lnJ2XMeta?.name ?? "experiences-parentId-serviceId",
    path: indexa3RE5lnJ2XMeta?.path ?? "/experiences/:parentId()/:serviceId()",
    meta: indexa3RE5lnJ2XMeta || {},
    alias: indexa3RE5lnJ2XMeta?.alias || [],
    redirect: indexa3RE5lnJ2XMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/experiences/[parentId]/[serviceId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91bookingId_93UDNW236VSCMeta?.name ?? "experiences-parentId-serviceId-return-bookingId",
    path: _91bookingId_93UDNW236VSCMeta?.path ?? "/experiences/:parentId()/:serviceId()/return/:bookingId()",
    meta: _91bookingId_93UDNW236VSCMeta || {},
    alias: _91bookingId_93UDNW236VSCMeta?.alias || [],
    redirect: _91bookingId_93UDNW236VSCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/experiences/[parentId]/[serviceId]/return/[bookingId].vue").then(m => m.default || m)
  },
  {
    name: _91accessKey_93QOrOf6EIs2Meta?.name ?? "gratuity-accessKey",
    path: _91accessKey_93QOrOf6EIs2Meta?.path ?? "/gratuity/:accessKey()",
    meta: _91accessKey_93QOrOf6EIs2Meta || {},
    alias: _91accessKey_93QOrOf6EIs2Meta?.alias || [],
    redirect: _91accessKey_93QOrOf6EIs2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/gratuity/[accessKey].vue").then(m => m.default || m)
  },
  {
    name: loginPPEL7a4GtYMeta?.name ?? "login",
    path: loginPPEL7a4GtYMeta?.path ?? "/login",
    meta: loginPPEL7a4GtYMeta || {},
    alias: loginPPEL7a4GtYMeta?.alias || [],
    redirect: loginPPEL7a4GtYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/login.vue").then(m => m.default || m)
  },
  {
    name: reset_45password0OJAAz7DRWMeta?.name ?? "reset-password",
    path: reset_45password0OJAAz7DRWMeta?.path ?? "/reset-password",
    meta: reset_45password0OJAAz7DRWMeta || {},
    alias: reset_45password0OJAAz7DRWMeta?.alias || [],
    redirect: reset_45password0OJAAz7DRWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: set_45passwordIKgtAPxc4jMeta?.name ?? "set-password",
    path: set_45passwordIKgtAPxc4jMeta?.path ?? "/set-password",
    meta: set_45passwordIKgtAPxc4jMeta || {},
    alias: set_45passwordIKgtAPxc4jMeta?.alias || [],
    redirect: set_45passwordIKgtAPxc4jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src641291346/src/gx-booking/pages/set-password.vue").then(m => m.default || m)
  }
]